import React from "react";

import { SectionSubHeading } from "../../../styles";
// import Menu from "./menu";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

// const Background = styled.div`
//   background: url("/images/mainbg.png");
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   min-height: 100vh;
//   display: flex;
//   align-items: center;
//   @media screen and (max-width: 899px) {
//     padding: 100px 0 0 0;
//   }
// `;
// const ReadMore = styled.a`
//   text-decoration: none;
//   background-color: #fff;
//   cursor: pointer;
//   background: linear-gradient(to top, #d9640d, #fccb00);
//   border-radius: 11px;
//   padding: 10px 30px 10px 30px;
//   font-family: "Poppins-Regular";
//   font-size: 14px;
//   margin-right: 3px;
//   box-shadow: ${(props) =>
//     props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"};
//   &:hover {
//     opacity: 0.8;
//   }
// `;
// const View = styled.a`
//   text-decoration: none;
//   background-color: #fff;
//   cursor: pointer;
//   background: transparent;
//   border: 1px solid #fff;
//   border-radius: 11px;
//   padding: 10px 30px 10px 30px;
//   font-family: "Poppins-Regular";
//   font-size: 14px;
//   margin-right: 3px;
//   &:hover {
//     opacity: 0.8;
//   }
// `;
const P = styled.p`
  font-family: "Poppins-Regular";
  text-align: center !important;
  font-size: ${(props) => (props.fs ? props.fs : "12px")};
  color: #fff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    /* font-size: 14px; */
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
// const Title = styled.span`
//   color: #fccb00;
//   font-weight: 600;
//   font-size: 18px;
// `;
const Intro = styled(Grid)`
  justify-content: center;
  width: 100%;
  padding: 50px 350px 0;
  display: flex;
  align-items: end;
  @media screen and (max-width: 1099px) {
    padding: 50px 300px 0;
  }
  @media screen and (max-width: 899px) {
    padding: 50px 200px 0;
  }
  @media screen and (max-width: 699px) {
    padding: 50px 100px 0;
  }
  @media screen and (max-width: 599px) {
    padding: 50px 10px 0;
  }
`;
// const Text = styled(Grid)`
//   padding: 0px 0 0 0;
//   display: flex;
//   align-items: end;
//   justify-content: center;
//   @media screen and (max-width: 899px) {
//     padding: ${(props) => (props.p599 ? props.p599 : "")};
//   }
// `;
// const VideoBK = styled.video`
//   /* width: 100%;
//   height: auto;
//   display: block; */
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   z-index: -1;
// `;
// const HomeImage = styled.img`
//   width: 100%;
//   height: auto;
//   max-width: 278px;
// `;
const DivWraper = styled.div`
  width: 100%;
  /* background-image: url("./images/aboutBackground.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 100px 0 100px;
  @media screen and (max-width: 1499px) {
    background-size: 100% 100%;
    margin: 100px 0;
  }
  @media screen and (max-width: 899px) {
    background-size: cover;
    // margin: 100px 0;
  }
`;

// const InnerDiv = styled.div`
//   width: 100%;
//   height: 100px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const LogoImg = styled.img`
  width: 130px;
  max-width: 30px;
  margin-right: 10px;
`;
const InnerLogoGrid = styled(Grid)`
  width: 100%;
  height: 50px;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 799px) {
    align-items: center;
    justify-content: flex-start;
    margin-left: 40%;
    max-width: 250px;
  }
  @media (max-width: 699px) {
    align-items: center;
    justify-content: flex-start;
    margin-left: 0%;
  }
`;
const OuterGrid = styled(Grid)`
  padding: 0 480px;
  @media (max-width: 1099px) {
    padding: 0 300px;
  }
  @media (max-width: 799px) {
    padding: 0 200px;
  }
  @media (max-width: 599px) {
    padding: 0 100px;
  }
`;
const Index = () => {
  return (
    <DivWraper>
      {/* <VideoBK autoPlay loop muted>
        <source src="/images/bg.mp4" type="video/mp4" />
      </VideoBK> */}
      {/* <Menu /> */}
      {/* <Background> */}
      <Container
        maxWidth="xl"
        style={{ textAlign: "center", padding: "30px 0" }}
      >
        <SectionSubHeading>ABOUT</SectionSubHeading>

        <Grid container>
          <Intro xs={12} p599="20px 30px 0">
            <P>
              Dragons embody immense strength and mystical qualities, often
              depicted as colossal , airborne serpents or reptiles possessing
              magical, spiritual, or supernatural attributes. However, among the
              diverse dragon lore, one particular dragon stands out: the Shen Long 神龙
              which translates to the Jade Dragon.
              <P />
              <P>
                The Jade Dragon holds great significance as the most revered
                stone in China, earning the title of "Heaven's Stone Dragon."
                According to belief, these stones originated from the essence of
                majestic mountains and the celestial breath of Heaven and Earth.
              </P>
              <P>
                Symbolically, Jade Dragons represent unparalleled supremacy
                among all creatures, embodying power, wisdom, and the bestowal
                of good fortune.
              </P>
              <P>
                In its pursuit of destiny, the Jade Dragon emerges to revitalize
                the crypto realm, breathing new vitality into the landscape. It
                aspires to ascend as the foremost Chinese memecoin, surpassing
                all its counterparts.
              </P>
              <P>
                With its immense power, the Jade Dragon aims to combat
                malevolent forces within the crypto domain, vanquishing
                disruptive entities that sow chaos within the cryptoverse.
              </P>
              <P>
                We invite you to join our noble cause, embarking on a righteous
                mission to confront the dark forces that plague our universe.
                Together, we shall triumph over evil, bringing an end to its
                reign once and for all!
              </P>
              <P>Welcome to Shen Long 神龙, The Jade Dragon!</P>
              {/* <ReadMore href="mailto:info@jadedragon.finance" >
                Contact us
              </ReadMore> */}
            </P>
          </Intro>
          <OuterGrid container>
            <InnerLogoGrid xs={12} md={4}>
              <LogoImg src="./images/Etherscan.png" />
              <h6>Etherscan</h6>
            </InnerLogoGrid>
            <InnerLogoGrid xs={12} md={4}>
              <LogoImg src="./images/Uniswap.png" />
              <h6>Uniswap</h6>
            </InnerLogoGrid>
            <InnerLogoGrid xs={12} md={4}>
              <LogoImg src="./images/Dextools.png" />
              <h6>Dextools</h6>
            </InnerLogoGrid>
          </OuterGrid>
          {/* <Text xs={12} md={8}>
             <P style={{textAlign:'center'}}>
             Throughout history, tales of dragons have captivated the imaginations of people worldwide. Like other legendary
creatures, dragons have been interpreted differently across various cultures.
            </P>
          </Text>
          
          <Text xs={12} md={2}>
            
            </Text>
            <Text xs={12} md={2}>
            
          </Text>
          <Text xs={12} md={8}>
            <div>
              <ReadMore>Buy Now</ReadMore>
              <View>Buy Now</View>

              </div>
          </Text> */}

          {/* <Text xs={12} md={2}>
            
            </Text> */}
        </Grid>
      </Container>
      {/* </Background> */}
    </DivWraper>
  );
};

export default Index;
