import React from "react";

import { DataImage, P } from "../../../styles";
import Menu from "./menu";
import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";


const ReadMore = styled.a`
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
  background: linear-gradient(to top, rgb(98, 177, 76), rgb(4, 154, 72));
  border-radius: 11px;
  padding: 10px 30px 10px 30px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  margin-right: 3px;
  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.8;
  }
`;
const View = styled.a`
  text-decoration: none;
  background-color: #fff;
  cursor: pointer;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 11px;
  padding: 10px 30px 10px 30px;
  font-family: "Poppins-Regular";
  font-size: 14px;
  margin-right: 3px;
  &:hover {
    opacity: 0.8;
  }
`;

const Intro = styled(Grid)`
  justify-content: center;

  padding: 50px 0 0 0;
  display: flex;
  align-items: ${(props) => (props.alignitem ? props.alignitem : "end")};
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
`;
const Text = styled(Grid)`
  padding: 0px 0 0 0;
  display: flex;
  align-items: end;
  justify-content: center;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
`;
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  filter: blur(15px);
`;
const GridStyle = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 10% 0px; */
  position: relative;
  z-index: 1;
`
const GridName = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 8% 0px;
  z-index: 0;
  @media(max-width: 599px){
    padding: 14% 0px;
  }
`
const DataImageExt =styled(DataImage)`
    position: absolute;
    z-index: 1;
    bottom: -187%;
`
const Index = () => {
  return (
    <div>
      <VideoBK autoPlay loop muted>
        <source src="/images/bg.mp4" type="video/mp4" />
      </VideoBK>
      <Menu />
      {/* <Background> */}
      <Container maxWidth="lg">
        <Grid container>
          <GridName item xs={12}>
            <DataImageExt  mw='90%' src={'/images/headerimg.png'}/>
            
          </GridName>
          <GridStyle item xs={12}>
            <DataImage mw='25%' mw599='40%' src={'/images/jadedragon.png'}/>
            <Text xs={12} md={3}></Text>
          <Text xs={12} md={6}>
            <P fs="12px" style={{ textAlign: "center" }}>
              Throughout history, tales of dragons have captivated the
              imaginations of people worldwide. Like other legendary creatures,
              dragons have been interpreted differently across various cultures.
            </P>
          </Text>

          <Text xs={12} md={3}></Text>
          <Text xs={12} md={2}></Text>
          <Text xs={12} md={8}>
            <div>
              <ReadMore>Buy Now</ReadMore>
              <View>View Chart</View>
            </div>
          </Text>

          <Text xs={12} md={2}></Text>
          </GridStyle>
          
        </Grid>
      </Container>
      {/* </Background> */}
    </div>
  );
};

export default Index;
