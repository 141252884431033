import styled from "@emotion/styled";

const SectionWrapper = styled.div`
  /* background-color: ${(props) =>
    props.bgColor ? props.bgColor : "#000000"}; */
  padding: ${(props) => (props.p ? props.p : "0")};
  margin-top: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "0")};
    margin-top: ${(props) => (props.mt ? props.mt : "0")};
  }
`;
const P = styled.p`
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: #fff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    /* font-size: 14px; */
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const DataImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props=>props.mw? props.mw : ""};
    padding: ${props=>props.pd? props.pd : ""};
    margin: ${props=>props.m? props.m : ""};
    @media (max-width: 599px){
        max-width: ${props=>props.mw599? props.mw599 : ""};;
    }
`
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const SectionHeading = styled.h2`
  text-transform: uppercase;
  text-shadow: 2px -2px #00a03d, -3px 0px #af1a06;
  color: #fccb00;
  font-size: 70px;
  font-family: "Poppins-Medium";
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  line-height: 80px;
  margin-top: ${(props) => (props.m ? props.m : "")};
  @media (max-width: 899px) {
    font-size: 50px;
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.P599 ? props.p599 : "70px 0 0 0")};
    line-height: unset;
    font-size: 40px;
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "Poppins-Medium";
  font-size: ${(props) => (props.fs ? props.fs : "60px")};
  color: #fff;
  padding: ${(props) => (props.p ? props.p : "0px 0 0px 0")};
  text-transform: uppercase;
  text-shadow: 2px -2px #00a03d, -3px 0px #af1a06;
  color: #fccb00;
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "30px")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-Medium";
  font-size: ${(props) => (props.fs ? props.fs : "30px")};
  /* text-transform: ${(props) => (props.tt ? props.tt : "uppercase")}; */

  color: ${(props) => (props.color ? props.color : "#FCCB00")};
  padding: 0;
  margin: 0;
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;

export {
  SectionWrapper,
  P,
  DataImage,
  SectionHeading,
  SectionSubHeading,
  CustomContainer,
  SectionSmallHeading,
};
