import styled from "@emotion/styled";
import logo from "./mainNavLogo.png";
import { DesktopMainMenu, MenuLink } from "./styles";

const Div = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: 45%;
  word-break: keep-all;
  @media screen and (max-width: 1260px) {
    min-width: 50%;
  }
  @media screen and (max-width: 1055px) {
    min-width: 60%;
  }
`;
const LogoImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
`;

const DesktopMenu = (props) => {
  const menulist = props.menuList.map(function (value, i) {
    return (
      <MenuLink key={i} href={value[1]}>
        {value[0]}
      </MenuLink>
    );
  });
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/">
        <LogoImage src="./images/logo2.png" />
      </MenuLink>
      <Div>{menulist}</Div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
