import "./App.css";
import S1_Header from "./pages/MainPage/S1_Header";
import S3_About from "./pages/MainPage/S3_about";

import S2_Jadenomics from "./pages/MainPage/S2_Jadenomics";
import S4_Community from "./pages/MainPage/S4_Community";

function App() {
  return (
    <div>
      <S1_Header />
      <S3_About />
      <S2_Jadenomics />
      <S4_Community />
    </div>
  );
}

export default App;
